import React from 'react'
import styled, { css } from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'
import { up, addResponsivity } from '../lib/styles'
import links from '../lib/links'
import { trimSlash } from '../lib/utils'

const LinkWrapper = styled.div`
  ${({
    theme: { colors },
    width,
  }) => css`
    display: inline-block;
    width: ${width};
  `}
`

// automatically fill route path with translated route
// automatically prepend path with /en/ or /de/ etc...
// when preferLang --> generate link in that lang === use to switch to other lang
//
const Link = ({
  to, // page route constant or dynamic route with /key/slug (eg. blog/article-slug)
  asSpan,
  asA,
  href, // only for asA
  target,
  alt,
  onClick,
  children,
  // following is for user switching lang (TODO: in next version move to separate fn)
  ...rest
}) => {
  const link = to

  const handleClick = (e, { isA, href } = {}) => {
    if (onClick) {
      onClick(e)
    }
    if (isA) {
      // Deal with anchor link on our page
      if (href && !href.startsWith('http') && href.indexOf('#') !== -1) {
        setTimeout(() => {
          const targetId = href.substring(href.indexOf('#') + 1)

          window.scroll({
            top: document.getElementById(targetId).offsetTop - 24, // subtract height of pinned menu on desktop
            behavior: 'smooth',
          })
        }, 100)

        e.preventDefault()
      }
    }
  }

  return (
    <LinkWrapper {...rest}>
      {asSpan && <span onClick={handleClick}>{children}</span>}
      {asA && (
        <a href={href} target={target} alt={alt} onClick={e => handleClick(e, { isA: true, href })}>
          {children}
        </a>
      )}
      {!asSpan && !asA && (
        <GatsbyLink to={link} onClick={handleClick}>
          {children}
        </GatsbyLink>
      )}
    </LinkWrapper>
  )
}

// PAGE ROUTES CONSTS
Object.keys(links).forEach(link => {
  Link[link] = links[link]
})

export default Link
